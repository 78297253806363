<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <div class="header-title">HR부(인재개발) 소개</div>
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-history-back">
          <a href="javascript:" @click="goBack" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk-hrdt">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="hrdt-container">
        <!-- hrdt-section-team -->
        <div class="hrdt-section hrdt-section-team">
          <div class="">
            <!-- hrdt-article:01 -->
            <div class="hrdt-article hrdt-article-01">
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="@/assets/lxp/mobile/images/_helpdesk/img_hrdt_01.png" alt="">
              </div>
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">자율적인 학습문화를 만듭니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">제 몫을 다하고 스스로 학습하는 문화 정착을 위한 상시학습 프로그램 및 자기주도 학습 지원 프로그램 운영, 새로운 학습경험을 통한 학습 동기부여를
                    마련합니다.</p>
                </div>
              </div>
            </div>
            <!-- hrdt-article:02 -->
            <div class="hrdt-article hrdt-article-02">
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="@/assets/lxp/mobile/images/_helpdesk/img_hrdt_02.png" alt="">
              </div>
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">변화에 유연하게 대응합니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">디지털 전환 등 급격한 금융환경 변환에 대처하고, 전문성을 확보하기 위한 미래 성장 분야 우수인재 육성 프로그램을 운영합니다.</p>
                </div>
              </div>
            </div>
            <!-- hrdt-article:03 -->
            <div class="hrdt-article hrdt-article-03">
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="@/assets/lxp/mobile/images/_helpdesk/img_hrdt_03.png" alt="">
              </div>
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <p class="subtitle">KB HR부(인재개발)는</p>
                  <h3 class="title">기본에 충실하게 준비합니다</h3>
                </div>
                <div class="content-description">
                  <p class="text">경영철학 가치공유를 통한 바람직한 KB人의 Mind Set, ‘KB다움’ 문화확산 및 바람직한 리더십 역할 함양을 위해 노력합니다.</p>
                </div>
              </div>
            </div>
            <!-- hrdt-article:04 -->
            <div class="hrdt-article hrdt-article-04">
              <!-- hrdt-section > hrdt-image -->
              <div class="hrdt-image">
                <img src="@/assets/lxp/mobile/images/_helpdesk/img_hrdt_04.png" alt="">
              </div>
              <!-- hrdt-section > hrdt-content -->
              <div class="hrdt-content">
                <div class="content-header">
                  <h3 class="title">함께, 다 함께</h3>
                </div>
                <div class="content-description">
                  <p class="text">학습의 즐거움을 함께, 다 함께 누리고, 모든 직원의 성장과
                    발전, 개인의 목표를 위해 나아가는데 디딤돌이 되겠습니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- hrdt-section-location -->
        <div class="hrdt-section hrdt-section-location ">
          <div class="location-container">
            <div class="location-tabs">
              <nav class="snb">
                <ul class="snb-list">
                  <li class="snb-item" :class="{'is-active' : trnct.value === 'a'}">
                    <button @click="clickTab('a')">여의도</button>
                  </li>
                  <li class="snb-item" :class="{'is-active' : trnct.value === 'b'}">
                    <button @click="clickTab('b')">천안 연수원</button>
                  </li>
                  <li class="snb-item" :class="{'is-active' : trnct.value === 'c'}">
                    <button @click="clickTab('c')">일산 연수원</button>
                  </li>
                  <li class="snb-item" :class="{'is-active' : trnct.value === 'd'}">
                    <button @click="clickTab('d')">속초 연수원</button>
                  </li>
                  <li class="snb-item" :class="{'is-active' : trnct.value === 'e'}">
                    <button @click="clickTab('e')">대천 연수원</button>
                  </li>
                </ul>
              </nav>

            </div>
            <div class="location-maps">
              <div class="map">
                <img :src="trnct.src" style="width: 100%; height: 100%;"/>
              </div>
              <div class="map-content">
                <dl class="content content-top">
                  <dt class="content-title"></dt>
                  <dd class="content-text">미래를 이끌어갈 인재 양성의 산실 국민은행 연수원에서 여러분의 비전을 마음껏 펼쳐보세요.</dd>
                </dl>
                <dl class="content">
                  <dt class="content-title">주소</dt>
                  <dd class="content-text">{{ trnct.address }}</dd>
                  <dt class="content-title">전화</dt>
                  <dd class="content-text">{{ trnct.tel }}</dd>
                  <dt v-if="trnct.value === 'a'" class="content-title">팩스</dt>
                  <dd v-if="trnct.value === 'a'" class="content-text">{{ trnct.fax }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {ref} from "vue";
import {useRouter} from "vue-router";
export default {
  name: 'HelpMobileHrdIntro',
  setup() {

    const trnct = ref({});
    const router = useRouter();
    const trnctList = ref([
      {label: '여의도', tab: '여의도', value: 'a', address: '서울 영등포구 의사당대로 141   우)07332',
        tel: '02) 2073-5352, 5368, 5364', fax: '02) 2073-5369', src: require('../../../../assets/lxp/images/helpdesk/trnct_yeouido.jpg')},
      {label: 'KB국민은행천안연수원', tab: '천안 연수원', value: 'b', address: '충남 천안시 동남구 문암로 283   우)31065',
        tel: '02) 2073-5352, 5368, 5364', fax: '', src: require('../../../../assets/lxp/images/helpdesk/trnct_cheonan_1.jpg')},
      {label: 'KB국민은행일산연수원', tab: '일산 연수원', value: 'c', address: '경기 고양시 일산동구 탄중로 393-66    우)10330',
        tel: '02) 2073-7878', fax: '', src: require('../../../../assets/lxp/images/helpdesk/trnct_ilsan.jpg')},
      {label: 'KB금융그룹속초연수원', tab: '속초 연수원', value: 'd',  address: '강원 속초시 미시령로 3287   우)24812',
        tel: '033)635-2281', fax: '', src: require('../../../../assets/lxp/images/helpdesk/trnct_sokcho4.jpg')},
      {label: 'KB국민은행대천연수원', tab: '대천 연수원', value: 'e', address: '충남 보령시 해수욕장3길 23   우)33487',
        tel: '041)932-4911', fax: '', src: require('../../../../assets/lxp/images/helpdesk/trnct_daecheon.jpg')},
    ])

    const clickTab = (val) => {
      trnct.value = trnctList.value.filter(x => x.value == val)[0]
    }

    clickTab('a');

    const goBack = () => {
      router.go(-1);
    }

    return{
      trnctList,
      trnct,
      clickTab,
      goBack
    }
  }
};
</script>